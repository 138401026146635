import { initialize, track } from "@iterable/web-sdk";

const IS_STAGING = useTheme()?.IS_STAGING;

const JWT_SECRET = IS_STAGING
  ? import.meta.env.VITE_ITERABLE_JWT_SECRET_STAGING
  : import.meta.env.VITE_ITERABLE_JWT_SECRET_PROD;

const API_KEY = IS_STAGING
  ? import.meta.env.VITE_ITERABLE_API_KEY_STAGING
  : import.meta.env.VITE_ITERABLE_API_KEY_PROD;

// Separate JWT utilities into their own functions
const createJWTPayload = (email: string) => ({
  email,
  exp: Math.floor(Date.now() / 1000) + 60 * 60 * 24,
  iat: Math.floor(Date.now() / 1000),
});

const base64Url = (str: string) =>
  btoa(str).replace(/\+/g, "-").replace(/\//g, "_").replace(/=+$/, "");

async function createToken(email: string) {
  const header = { alg: "HS256", typ: "JWT" };
  const payload = createJWTPayload(email);

  const encodedHeader = base64Url(JSON.stringify(header));
  const encodedPayload = base64Url(JSON.stringify(payload));

  const encoder = new TextEncoder();
  const data = encoder.encode(`${encodedHeader}.${encodedPayload}`);
  const secretBuffer = encoder.encode(JWT_SECRET);

  const key = await crypto.subtle.importKey(
    "raw",
    secretBuffer,
    { name: "HMAC", hash: "SHA-256" },
    false,
    ["sign"]
  );

  const signature = await crypto.subtle.sign("HMAC", key, data);

  const signatureBase64 = base64Url(
    String.fromCharCode(...new Uint8Array(signature))
  );
  return `${encodedHeader}.${encodedPayload}.${signatureBase64}`;
}

// Initialize client with better error handling
export const initializeIterableClient = async (email?: string) => {
  const { customer } = useTheme();
  const userEmail = email || customer?.email;

  if (!userEmail || !JWT_SECRET || !API_KEY) {
    console.error("No email provided to initialize Iterable client");
    return;
  }

  const client = initialize(API_KEY, () => createToken(userEmail));

  try {
    await client.setEmail(userEmail);
    return client;
  } catch (error) {
    console.error("Failed to set email for Iterable client:", error);
    throw error;
  }
};

// Track events with better type safety
export type IterableEvent = {
  eventName: string;
  dataFields: Record<string, unknown>;
};

export const trackEvent = async (event: IterableEvent) => {
  try {
    const { data } = await track(event);
    return { ...data, code: "Success" };
  } catch (error) {
    console.error(`Failed to track event ${event.eventName}:`, error);
    return { error, code: "Error" };
  }
};
